import { USER_PROFILE_VALIDATION_PATTERN } from '@goparrot/customer-sdk';
import { BirthdayFieldPolicyEnum } from '@goparrot/store-v2-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import type { UserFields } from '@webstore-monorepo/shared/interfaces';
import { validatePhoneNumber } from '@webstore-monorepo/shared/utils/forms';
import { Box, Stack } from '@webstore-monorepo/ui/box';
import { DatePicker } from '@webstore-monorepo/ui/date-picker';
import { Input } from '@webstore-monorepo/ui/input';
import { PhoneInput } from '@webstore-monorepo/ui/phone-input';
import { Text } from '@webstore-monorepo/ui/text';
import type { FormikConfig, FormikProps } from 'formik';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import type { PropsWithChildren } from 'react';
import React, { forwardRef, memo, useState } from 'react';
import type { CountryCode } from 'react-native-country-picker-modal';
import type { DateSchema, StringSchema } from 'yup';
import { date, object, string } from 'yup';

import { FormikEffect } from '../FormikEffect';

export interface NewUserFormProps {
  birthdayFieldStatus?: BirthdayFieldPolicyEnum;
  isOptionalEmailInMobileAppOnly?: boolean;
  disabled?: boolean;
  user?: UserFields;
  fullWidth?: boolean;
  autoFocus?: boolean;
  showErrors?: boolean;
  onSubmit?: (values: UserFields) => Promise<void>;
  onUserChange?: (values: UserFields) => void;
  onUserTouched?: (values: any) => void;
  userTouched?: any;
  showPhoneNumberInput?: boolean;
  showBirthDayPicker?: boolean;
  phoneLabel?: string;
  setCurrentUserData?: React.Dispatch<React.SetStateAction<UserFields>>;
  formikConfig?: Partial<FormikConfig<UserFields>>;
}

interface SignupShape {
  firstName: StringSchema<string>;
  lastName: StringSchema<string>;
  email?: StringSchema<string>;
  birthday?: DateSchema<Date> | undefined;
}

const maxBirthdayDate = DateTime.fromJSDate(new Date()).minus({ years: 12 }).toJSDate();
const startBirthdayDate = new Date('2000/01/01');
const birthDayFormat = 'MM/dd/yyyy';

const getBirthdayShape = (maxDate: Date, isBirthdayRequired: boolean, birthDayFormat: string) => {
  const birthDayShapeForRequired = date()
    .required('Please provide your birthday')
    .max(maxDate, `Maximum date should be ${DateTime.fromJSDate(maxDate).toFormat(birthDayFormat)}`)
    .nullable(true);

  const birthDayShapeForOptional = date()
    .max(maxDate, `Maximum date should be ${DateTime.fromJSDate(maxDate).toFormat(birthDayFormat)}`)
    .nullable(true);

  return isBirthdayRequired ? birthDayShapeForRequired : birthDayShapeForOptional;
};

const getEmailShape = (isOptionalEmailInMobileAppOnly: boolean) => {
  const emailShapeForRequired = string().email('Your email address format is incorrect').max(70, 'Too Long!').required('Please provide your email').trim();

  const emailShapeForOptional = string().notRequired().email('Your email address format is incorrect').max(70, 'Too Long!').nullable(true).trim();

  return isOptionalEmailInMobileAppOnly ? emailShapeForOptional : emailShapeForRequired;
};

const SignupShape: SignupShape = {
  // @ts-ignore
  formattedPhoneNumber: string().test('is-phone', 'Please enter a valid phone number', (value) => {
    if (value) {
      return validatePhoneNumber(value);
    }
    return true;
  }),
  // @ts-ignore
  phoneNumber: string().min(10, 'Too Short!').required('Enter a valid 10-digit phone number.'),
  // @ts-ignore
  firstName: string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(USER_PROFILE_VALIDATION_PATTERN, 'Sorry, only letters (A-z) are allowed')
    .required('Enter your first name'),
  // @ts-ignore
  lastName: string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(USER_PROFILE_VALIDATION_PATTERN, 'Sorry, only letters (A-z) are allowed')
    .required('Enter your last name'),
};

export const NewUserForm = memo(
  forwardRef((props: NewUserFormProps, ref?: any) => {
    const [focusDate, setFocusDate] = useState<boolean>(false);
    const { sharedComponents } = useComponentsConfig() ?? {};
    const { input } = sharedComponents ?? {};
    const store = useStoreState();
    const filteredCodes = store?.country?.codeFilters?.filter(Boolean) || [];
    const hasSingleCountry = filteredCodes.length === 1;
    const codeFilters = filteredCodes.map((code) => code.toUpperCase() as CountryCode);
    const country = store?.country;
    const defaultCode = (
      country?.defaultCode && (codeFilters.includes(country.defaultCode.toUpperCase() as CountryCode) || codeFilters.length === 0)
        ? country.defaultCode
        : codeFilters?.includes('US')
        ? 'US'
        : filteredCodes?.[0] ?? 'US'
    ).toUpperCase() as CountryCode;

    const handleBirthdayPickerPress = () => {
      if (focusDate) {
        return;
      }
      setFocusDate(true);
    };

    const birthdayFieldStatus = props.birthdayFieldStatus;
    const isBirthdayRequired = BirthdayFieldPolicyEnum.REQUIRED === birthdayFieldStatus;
    const isOptionalEmailInMobileAppOnly = false;

    if (BirthdayFieldPolicyEnum.DISABLED !== birthdayFieldStatus) {
      // @ts-ignore
      SignupShape.birthday = getBirthdayShape(maxBirthdayDate, isBirthdayRequired, birthDayFormat);
    }

    // @ts-ignore
    SignupShape.email = getEmailShape(isOptionalEmailInMobileAppOnly ?? false);

    const SignupSchema = object().shape({
      ...SignupShape,
    });

    const handleSubmit = async (values: UserFields) => {
      const data = { ...values };
      if (birthdayFieldStatus === BirthdayFieldPolicyEnum.DISABLED) {
        delete data.birthday;
      }
      return props.onSubmit && props.onSubmit(data);
    };

    return (
      <Formik
        enableReinitialize
        innerRef={ref}
        initialValues={{
          firstName: props?.user?.firstName ?? '',
          lastName: props?.user?.lastName ?? '',
          email: props?.user?.email ?? '',
          birthday: props?.user?.birthday ?? '',
          phoneNumber: props?.user?.phoneNumber ?? '',
        }}
        initialTouched={props.userTouched}
        validationSchema={SignupSchema}
        validateOnChange
        validateOnBlur
        validateOnMount
        onSubmit={handleSubmit}
        {...props.formikConfig}
      >
        {({ setFieldValue, values, handleChange, errors, touched, setFieldTouched }: PropsWithChildren<FormikProps<UserFields>>) => (
          <Stack
            zIndex={1}
            direction="column"
            size="l"
            width={{
              phone: '100%',
            }}
          >
            <FormikEffect onChange={props.onUserChange} setTouched={props.onUserTouched} values={values} touched={touched} />
            {props.showPhoneNumberInput ? (
              <Box zIndex={10}>
                <PhoneInput
                  newStyle
                  disabled={props.disabled}
                  label="Phone Number"
                  autoFocus={props.autoFocus}
                  value={values.phoneNumber}
                  testID="phone-input"
                  onChangeText={handleChange('phoneNumber')}
                  onChangeCountry={(event: any) => {
                    handleChange('country')({ ...event, countryCode: event.cca2, dialCode: event.callingCode[0], format: '' });
                  }}
                  onChangeFormattedText={handleChange('formattedPhoneNumber')}
                  disableArrowIcon={hasSingleCountry}
                  disabledCountrySelect={hasSingleCountry}
                  defaultCode={defaultCode}
                  countryPickerProps={{
                    countryCodes: codeFilters,
                  }}
                  textInputProps={{
                    onBlur: () => {
                      setFieldTouched('phoneNumber');
                    },
                  }}
                  textInputStyle={{
                    fontSize: 16,
                  }}
                  error={!!(errors.phoneNumber || errors.formattedPhoneNumber)}
                  touched={!!touched.phoneNumber || !!touched.formattedPhoneNumber}
                  errorMessage={errors.phoneNumber || errors.formattedPhoneNumber}
                />
              </Box>
            ) : null}
            <Box>
              <Input
                newStyle
                testID="email-input"
                editable={!props.disabled}
                accessibilityLabel="Email"
                accessibilityHint="Ensure only your email address is entered here"
                autoCapitalize="none"
                keyboardType="email-address"
                onChangeText={handleChange('email')}
                onBlur={() => {
                  setFieldTouched('email');
                  setFieldValue('email', values.email?.trim());
                }}
                value={values.email}
                inputState={{
                  error: !!errors.email,
                  touched: !!touched.email,
                }}
                errorMessage={errors.email}
                placeholder="Email"
                label="Email"
              />
            </Box>
            <Box flexDirection="row">
              <Box mr={4} flex={1}>
                <Input
                  newStyle
                  testID="first-name-input"
                  required
                  editable={!props.disabled}
                  accessibilityLabel="First Name"
                  accessibilityHint="Ensure only your first name is entered here"
                  autoFocus={props.autoFocus ?? true}
                  onChangeText={handleChange('firstName')}
                  onBlur={() => {
                    setFieldTouched('firstName');
                    setFieldValue('firstName', values.firstName.trim());
                  }}
                  value={values.firstName}
                  inputState={{
                    error: !!errors.firstName,
                    touched: !!touched.firstName,
                  }}
                  errorMessage={errors.firstName}
                  placeholder="First Name"
                  label="First Name"
                />
              </Box>
              <Box flex={1}>
                <Input
                  newStyle
                  testID="last-name-input"
                  required
                  editable={!props.disabled}
                  accessibilityLabel="Last name"
                  accessibilityHint="Ensure only your last name is entered here"
                  onChangeText={handleChange('lastName')}
                  onBlur={() => {
                    setFieldTouched('lastName');
                    setFieldValue('lastName', values.lastName.trim());
                  }}
                  value={values.lastName}
                  inputState={{
                    error: !!errors.lastName,
                    touched: !!touched.lastName,
                  }}
                  errorMessage={errors.lastName}
                  placeholder="Last Name"
                  label="Last Name"
                />
              </Box>
            </Box>
            {!props.showBirthDayPicker || birthdayFieldStatus === BirthdayFieldPolicyEnum.DISABLED ? null : (
              <Box width="100%">
                <Text color="gray700" fontSize="m" fontWeight="700" my="xs" {...input?.label?.style} testID="birthday-label">
                  Birthday
                  {isBirthdayRequired || !!values.birthday ? null : <Text> (optional)</Text>}
                </Text>
                <DatePicker
                  testID="birthday-input"
                  required={isBirthdayRequired}
                  hasError={!!errors.birthday}
                  isBirthdayRequired={isBirthdayRequired}
                  setTouched={() => setFieldTouched('birthday')}
                  setBirthday={(value: Date | null) => {
                    return setFieldValue('birthday', value);
                  }}
                  maxBirthdayDate={maxBirthdayDate}
                  startBirthdayDate={startBirthdayDate}
                  format={birthDayFormat}
                  value={values.birthday}
                  handleBirthDaySelect={(value) => {
                    setFieldValue('birthday', value);
                    setFocusDate(false);
                  }}
                  handleBirthdayPickerPress={handleBirthdayPickerPress}
                  handleBirthdayCancel={() => {
                    if (focusDate) {
                      setFocusDate(false);
                    }
                  }}
                  focusDate={focusDate}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  // @ts-ignore
                  userDate={values.birthday}
                  error={errors.birthday}
                  touched={!!touched.birthday}
                  placeholder=""
                />
              </Box>
            )}
          </Stack>
        )}
      </Formik>
    );
  }),
);
