import { useEffect } from 'react';
import type { UserFields } from '@webstore-monorepo/shared/interfaces';

interface FormikEffectProps {
  onChange?: (values: UserFields) => void;
  setTouched?: (touched: any) => void;
  touched?: any;
  values: UserFields;
  setCurrentUserData?: React.Dispatch<React.SetStateAction<UserFields>>;
}

export const FormikEffect = ({ onChange = () => {}, values, touched, setCurrentUserData = () => {}, setTouched = () => {} }: FormikEffectProps): null => {
  useEffect(() => setTouched(touched), [touched]);
  useEffect(() => setCurrentUserData(values), [values]);
  useEffect(() => onChange(values), [onChange, values]);
  return null;
};
