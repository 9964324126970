import { Box } from '@webstore-monorepo/ui/box';
import React from 'react';
import CountryPickerModal from 'react-native-country-picker-modal';

export const CountrySelect = (props: any) => {
  const { visible, ...rest } = props;
  const closeImage =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAIABAMAAAAGVsnJAAAAMFBMVEUAAAAAAAAAAAAAAAAAAAACAgICAgIBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQF1oY8TAAAAD3RSTlMAEDBQYJ+gsMDP0N/g7/A+6XEPAAAEJElEQVR42uzSsU1EMQAEUUuciK8ESqED3Am1XIvUwA9IMAVs6pOw9r0CJpoBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHOrlPra7zXGOj8cTmtc4xm393Pc31zxngLUeT2he5wywYoEdzTXPGSAW2NK8jhkgFtjTXPOUAWKBTc3rkAFigV3NNc8YIBbY1ryOGCAW2Ndc84QBYoENzXMW+FyxwMYB1vf4715XLLBxgPU+yhbIAcoWyAG6FsgByhbIAboWyAHKFsgBuhbIAcoWyAG6FsgByhbIAboWyAHKFsgBuhbIAcoWyAG6FsgBGhbIAWKAigVygK8xihbIAX7fxuhaIAcoWyAH6FogByhbIAfoWiAHKFsgB+haIAcoWyAH6FogByhbIAfoWiAHKFsgB+haIAcoWyAH6FogB2hYIAeIAVoWyAHKFsgBuhbIAcoWyAG6FsgByhbIAboWyAHKFsgBuhbIAcoWyAG6FsgByhbIAboWyAHKFsgBuhbIAaoWKBggFugaIBdoGyAXKBsgF2gbIBcoGyAXaBsgFygbIBdoGyAXKBsgF2gbIBcoGyAXKBwgF8gByhbIAboWyAEaFvhjl15O64iCMAg3eDxrhaCMbghWCg5pUnIINwhjrOdSgtoewQxVFcBP03yfBGwASAAAbAQAQEcAAGwEAEBG4BkAbAQAQEYAABztPgAkQACmdhsAEiAAV7sbAAncx9YOAGoC9/G1ywHMz/fPXo0P+PX+pT9KACBgAwACMgAgYAMAAjIAIGADAAI2ACQgA0ACNgAkYATwBgIyAPcbCLgAPG4g4AIwAwIuADMgIAMAAjYAICADAAI2ACBgA0ACMgAkYANAAjIAJGADQAIyACRgA0ACMgAkIAFgIEAAAGAhQAAyAgTgIkAAMgIE4CJAADICBOAiQAAyAgTgIkAAMgIE4CJAADICBOAiQAAyAgTgIkAAKgIzBOAiQAAyAgTgIkAAIgIE8PI4IyJAAMeMiAAB/H+YcREgABkBAnARIAAZAQJwESAAGQECcBEgABkBAnARIAAZAQJwESAAGQECcBEgABkBAnARIAABAQIAAA0BApARIAAXAQKQESAAFwECkBEgABcBApARIAAXAQKQESAAFwECkBEgABcBApARIAAXAQIwEVgC4PoEbgCwdPPfnL0NAJYSOOY6BI6Fm3jqqQmsv3XDUy9A4Fi6eV4AbMOtywj8nkt0A4BFm3/nGm0AsIjA08xlCBzLNwHgxG0AsGATAE5N4PiGTQA4cT8eZnnb01RVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVX20AwckAAAAAIL+v25HoAAAAAAAAAAAPAVGDrxfxfFw1QAAAABJRU5ErkJggg==';

  return visible ? (
    <Box
      position="absolute"
      width={{
        phone: '100%',
        tablet: 320,
      }}
      maxHeight={200}
      left={0}
      top={60}
      borderWidth={1}
      borderColor="gray200"
      accessibilityLabel="Country select input"
      accessibilityHint="select country you live in"
      style={{
        // @ts-ignore WEB ONLY
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <CountryPickerModal {...rest} withModal={false} withEmoji={false} closeButtonImage={closeImage} />
    </Box>
  ) : null;
};
