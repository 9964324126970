import type { ReadIsLoyaltyProgramAvailableDtoV3 } from '@goparrot/loyalty-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { QueryKeysEnum } from './keys';

export const useHasLoyalty = (
  storeId: string,
  options?: UseMutationOptions<ReadIsLoyaltyProgramAvailableDtoV3, AxiosError, void>,
): UseMutationResult<ReadIsLoyaltyProgramAvailableDtoV3, AxiosError, void> => {
  return useMutation(async () => axios.get(`/api/v3/stores/${storeId}/loyalty/available-loyalty-program`), options);
};

export const useHasLoyaltyQuery = (
  storeId: string,
  options?: UseQueryOptions<ReadIsLoyaltyProgramAvailableDtoV3, AxiosError, ReadIsLoyaltyProgramAvailableDtoV3, any>,
): UseQueryResult<ReadIsLoyaltyProgramAvailableDtoV3, AxiosError> => {
  const getLoyalties = async (): Promise<ReadIsLoyaltyProgramAvailableDtoV3> => {
    return axios.get(`/api/v3/stores/${storeId}/loyalty/available-loyalty-program`);
  };
  return useQuery([QueryKeysEnum.getAvailableLoyaltyPrograms], getLoyalties, { staleTime: Infinity, ...options });
};
