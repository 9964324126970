import { H1 as H1Expo, H2 as H2Expo } from '@expo/html-elements';
import type { ComponentType } from 'react';
import React from 'react';

export const H1: ComponentType<any> = ({ children, ...props }) => (
  <H1Expo {...props} style={{ fontSize: 'unset', marginVertical: 'unset', ...props.style }}>
    {children}
  </H1Expo>
);
export const H2: ComponentType<any> = ({ children, ...props }) => (
  <H2Expo {...props} style={{ fontSize: 'unset', marginVertical: 'unset', ...props.style }}>
    {children}
  </H2Expo>
);
