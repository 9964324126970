import type { IOrder } from '@goparrot/order-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

export const useRemoveLoyaltyReward = (options?: UseMutationOptions<IOrder, AxiosError, string>): UseMutationResult<IOrder, AxiosError, string> => {
  const { storeId } = useWebStore();

  return useMutation(async (uuid) => {
    return axios.delete(`/api/v2/stores/${storeId}/loyalty-rewards/${uuid}`);
  }, options);
};
