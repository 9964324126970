import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';
import { Modal, Pressable } from 'react-native';

export type UiConfirmationModalProps = {
  onClose: () => void;
  isVisible: boolean;
  isLoading?: boolean;
  onConfirm: () => void;
  title: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
};

export const UiConfirmationModal = ({
  onClose,
  isVisible,
  isLoading = false,
  onConfirm,
  title,
  description,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}: UiConfirmationModalProps) => {
  return (
    <Modal animationType="fade" transparent onRequestClose={onClose} visible={isVisible}>
      <Box flexDirection="row" justifyContent="center" alignItems="center" minHeight="100%">
        <Pressable onPress={onClose} style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
          <Box backgroundColor="modalBackdrop" position="absolute" top={0} bottom={0} left={0} right={0} />
        </Pressable>
        <Box backgroundColor="white" borderRadius="xl" px={8} py={8} maxWidth={343}>
          <Box marginBottom={8}>
            <Text marginBottom={4} fontWeight="700" lineHeight={32} color="gray900" fontSize="xxl" testID="delete-confirm-title">
              {title}
            </Text>
            {description ? (
              <Text fontSize="m" lineHeight={24} color="gray900" testID="delete-confirm-description">
                {description}
              </Text>
            ) : null}
          </Box>
          <Box flexDirection="row" alignItems="center" justifyContent="space-between">
            <Button size="md" onPress={onClose} backgroundColor="gray100" color="criticalText" buttonStyle={{ minWidth: 130 }} testID="delete-confirm-cancel">
              {cancelText}
            </Button>
            <Button
              size="md"
              isLoading={isLoading}
              color="white"
              backgroundColor="criticalFill"
              onPress={onConfirm}
              buttonStyle={{ minWidth: 130 }}
              testID="delete-confirm-submit"
            >
              {confirmText}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
