import type { ReadMerchantFeaturesFlagsDto, ReadStoreFeaturesFlagsDto } from '@goparrot/store-v2-sdk';
import { useMerchantState } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';

export const useGetFeatureFlag = (featureFlag: keyof ReadStoreFeaturesFlagsDto | keyof ReadMerchantFeaturesFlagsDto): boolean => {
  const merchant = useMerchantState();
  const store = useStoreState();

  if (featureFlag === 'isOrderThrottlingEnabled') {
    return !!merchant.featuresFlags?.isOrderThrottlingEnabled && !!store.featuresFlags?.isOrderThrottlingEnabled;
  }

  if (featureFlag === 'isDynamicMenuEnabled') {
    return true;
  }

  if (featureFlag === 'isMinMaxRelativeToGrossTotal') {
    return !!merchant.featuresFlags?.isMinMaxRelativeToGrossTotal && !!store.featuresFlags?.isMinMaxRelativeToGrossTotal;
  }

  if (featureFlag === 'isNewCheckoutFlowEnabled') {
    return true;
  }

  return !!(
    merchant.featuresFlags?.[featureFlag as keyof ReadMerchantFeaturesFlagsDto] &&
    ((store.featuresFlags?.[featureFlag as keyof ReadStoreFeaturesFlagsDto] as ReadStoreFeaturesFlagsDto) ??
      merchant.featuresFlags?.[featureFlag as keyof ReadMerchantFeaturesFlagsDto])
  );
};
