import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import React from 'react';

export interface SeparatorProps {
  mt?: keyof Theme['spacing'];
  mb?: keyof Theme['spacing'];
  color?: keyof Theme['colors'];
}
export const Separator: React.FC<SeparatorProps> = ({ mt = 'none', mb = 8, color = 'gray100' }) => {
  return <Box testID="checkout-separator" marginBottom={mb} marginTop={mt} backgroundColor={color} height={1} width="100%" />;
};
