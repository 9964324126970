import { useTheme } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { usePreviousState } from '@webstore-monorepo/shared/hooks/use-previous-state';
import { InfoIcon, LockIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Input } from '@webstore-monorepo/ui/input';
import { Text } from '@webstore-monorepo/ui/text';
import parsePhoneNumberUtils, { AsYouType } from 'libphonenumber-js';
import React, { forwardRef, memo, useEffect, useState } from 'react';
import type { StyleProp, TextInputProps, TextStyle, ViewStyle } from 'react-native';
import { Image, TouchableOpacity } from 'react-native';
import type { CallingCode, Country, CountryCode } from 'react-native-country-picker-modal';
import { CountryModalProvider, DARK_THEME, DEFAULT_THEME, Flag, getCallingCode } from 'react-native-country-picker-modal';
import type { CountryFilterProps } from 'react-native-country-picker-modal/lib/CountryFilter';

import { CountrySelect } from './CountrySelect';

const dropDown =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAi0lEQVRYR+3WuQ6AIBRE0eHL1T83FBqU5S1szdiY2NyTKcCAzU/Y3AcBXIALcIF0gRPAsehgugDEXnYQrUC88RIgfpuJ+MRrgFmILN4CjEYU4xJgFKIa1wB6Ec24FuBFiHELwIpQxa0ALUId9wAkhCnuBdQQ5ngP4I9wxXsBDyJ9m+8y/g9wAS7ABW4giBshQZji3AAAAABJRU5ErkJggg==';

export type PhoneInputProps = {
  newStyle?: boolean;
  label?: string;
  withDarkTheme?: boolean;
  withShadow?: boolean;
  autoFocus?: boolean;
  defaultCode?: CountryCode;
  value?: string;
  errorMessage?: string;
  noteMessage?: string;
  touched?: boolean;
  error?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  disableArrowIcon?: boolean;
  disabledCountrySelect?: boolean;
  placeholder?: string;
  onChangeCountry?: (country: Country) => void;
  onChangeText?: (text: string) => void;
  onChangeFormattedText?: (text: string) => void;
  renderDropdownImage?: JSX.Element;
  containerStyle?: StyleProp<ViewStyle>;
  textContainerStyle?: StyleProp<ViewStyle>;
  textInputProps?: TextInputProps;
  textInputStyle?: StyleProp<TextStyle>;
  // TODO: add type for restyle style props
  codeTextStyle?: Record<any, any>;
  flagButtonStyle?: Record<any, any>;
  countryPickerButtonStyle?: Record<any, any>;
  layout?: 'first' | 'second';
  filterProps?: CountryFilterProps;
  countryPickerProps?: any;
  onKeyPress?: (event: any) => void;
  hasError?: boolean;
  testID?: string;
};

export const PhoneInput = memo(
  forwardRef((props: PhoneInputProps, forwardedRef?: any) => {
    const {
      withDarkTheme,
      textInputProps,
      textInputStyle,
      autoFocus = true,
      disableArrowIcon,
      disabledCountrySelect,
      flagButtonStyle,
      containerStyle,
      textContainerStyle,
      renderDropdownImage,
      countryPickerProps = {},
      filterProps = {},
      countryPickerButtonStyle,
      layout = 'first',
      testID,
    } = props;
    const defaultCode: CountryCode = props.defaultCode ?? (parsePhoneNumberUtils(props.defaultValue ?? '')?.country as CountryCode) ?? 'US';
    const [showLabel, setShowLabel] = useState(!!props.value);
    const [modalVisible, setModalVisible] = useState(false);
    const [code, setCode] = useState<CallingCode | undefined>(defaultCode ? undefined : '1');
    const [countryCode, setCountryCode] = useState(defaultCode);
    const [number, setNumber] = useState(props.defaultValue ?? '');
    const [disabled, setDisabled] = useState(props.disabled || false);

    const prevState = usePreviousState({ disabled, number });

    const theme = useTheme<Theme>();
    const componentsConfig = useComponentsConfig();
    const { style: inputActiveStyle, error: inputError, inactive: inputInactiveStyle } = componentsConfig.sharedComponents?.input ?? {};
    const { options: phoneInputOptions } = componentsConfig.loginRegisterScreen?.phoneInputStep?.phoneInput ?? {};
    const inputStyle = props.disabled ? { ...inputActiveStyle, ...inputInactiveStyle?.style } : inputActiveStyle;

    useEffect(() => {
      if (disabled !== prevState?.disabled) {
        if ((props.value || props.value === '') && props.value !== prevState?.number) {
          setDisabled(disabled);
          setNumber(props.value);
          return;
        }
        setDisabled(disabled);
      }
    }, [props.value]);

    useEffect(() => {
      const asyncGetCallingCode = async (): Promise<void> => {
        if (!defaultCode) {
          return;
        }
        const callingCode = await getCallingCode(defaultCode);
        setCode(callingCode);
      };

      if (defaultCode) {
        void asyncGetCallingCode();
      }
    }, [defaultCode]);

    const onSelect = (country: any): void => {
      const { onChangeCountry } = props;
      setCountryCode(country.cca2);
      setCode(country.callingCode[0]);
      setNumber('');

      if (onChangeCountry) {
        onChangeCountry(country);
      }
    };

    const getFormattedNumber = (text: string): { formatted: string; originalNumber: string } => {
      const originalNumber = (text.match(/\d/g) ?? []).join('');
      // @ts-ignore contryCode type conflict with second parameter of parsePhoneNumberUtils, althought they seem to have similar types
      const originalNumberNational = parsePhoneNumberUtils(originalNumber, countryCode)?.nationalNumber ?? originalNumber;
      // @ts-ignore contryCode type conflict with second parameter of parsePhoneNumberUtils, althought they seem to have similar types
      const formatter = new AsYouType(countryCode);
      let formatted = '';
      formatter.reset();
      for (const c of originalNumberNational) {
        formatted = formatter.input(c);
      }
      return {
        formatted,
        originalNumber: originalNumberNational,
      };
    };

    const handleTextChange = (text: string): void => {
      const { onChangeText, onChangeFormattedText } = props;
      const { formatted, originalNumber } = getFormattedNumber(text);
      setNumber(formatted);
      if (onChangeText) {
        onChangeText(originalNumber);
      }
      if (onChangeFormattedText) {
        if (code) {
          onChangeFormattedText(originalNumber.length > 0 ? `+${code}${originalNumber}` : originalNumber);
        } else {
          onChangeFormattedText(originalNumber);
        }
      }
    };

    const renderFlagButton = (props: any): JSX.Element => {
      const { layout = 'first', flagSize = 25 } = props;
      if (layout === 'first') {
        return <Flag countryCode={countryCode} flagSize={flagSize ? flagSize : DEFAULT_THEME.flagSize} />;
      }
      return <Box />;
    };

    const renderDropdownImageControlled = (): JSX.Element => {
      return (
        <Image
          accessibilityLabel="Dropdown Image"
          accessibilityHint="dropdown image"
          accessibilityIgnoresInvertColors
          accessibilityRole="none"
          source={{ uri: dropDown }}
          resizeMode="contain"
          style={{
            height: 14,
            width: 12,
            marginRight: 5,
          }}
        />
      );
    };

    const getInputValue = () => {
      // @ts-ignore CountryCode type conflict with second parameter of parsePhoneNumberUtils, althought they seem to have similar types
      const formattedNumber = parsePhoneNumberUtils(number, countryCode)?.formatNational() ?? number;
      const [head, ...tail] = formattedNumber;
      if (head === '0') {
        return tail.join('');
      }
      return formattedNumber;
    };

    const handleSetShowLabel = (event: any) => {
      if (!props.disabled) {
        setShowLabel(true);
        textInputProps?.onFocus && textInputProps.onFocus(event);
      }
    };

    const handleHideLabel = (event: any) => {
      if (!props.disabled) {
        setShowLabel(!!getInputValue());
        textInputProps?.onBlur && textInputProps.onBlur(event);
      }
    };
    const messageComponent =
      props.error && props.touched ? (
        <Box
          nativeID={`input-error-message-${props.label?.split(' ').join('_')}`}
          {...(props.newStyle ? { flexDirection: 'row', alignItems: 'center' } : null)}
        >
          {props.newStyle ? <InfoIcon height={15} width={15} fill="danger" /> : null}
          <Text
            testID={`${props?.testID}-warning`}
            accessibilityLabel={`${props.label} has an error! ${props.errorMessage}`}
            accessibilityHint="This field has an error"
            fontSize="xs"
            color="danger"
            mt="xs"
            {...(props.newStyle ? { ml: 1 } : null)}
          >
            {props.errorMessage ?? ''}
          </Text>
        </Box>
      ) : props.noteMessage ? (
        <Box nativeID={`input-note-message-${props.label?.split(' ').join('_')}`}>
          <Text accessibilityLabel="note" accessibilityHint="field note" fontSize="xxs" mt="xxs" color="gray500">
            {props.noteMessage ?? ''}
          </Text>
        </Box>
      ) : null;

    return (
      <CountryModalProvider>
        <Box
          ref={forwardedRef}
          position="relative"
          backgroundColor={props.disabled ? inputInactiveStyle?.style?.backgroundColor ?? 'gray100' : inputStyle?.backgroundColor ?? 'white'}
          borderRadius={inputStyle?.borderRadius ?? 'xs'}
          borderWidth={inputStyle?.borderWidth ?? 1}
          borderColor={inputStyle?.borderColor ?? 'gray200'}
          minWidth={320}
          height={inputStyle?.height ?? 50}
          width={inputStyle?.width ?? '100%'}
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          alignContent="center"
          {...(containerStyle as object)}
          {...(props.newStyle
            ? {
                minHeight: 64,
              }
            : null)}
          {...(((props.error && props.touched) || props.hasError) && {
            // @ts-ignore TODO: update sdk types for error object
            borderColor: inputError?.style?.borderColor ?? 'danger',
            // @ts-ignore
            borderWidth: inputError?.style?.borderWidth ?? 1,
          })}
        >
          <TouchableOpacity
            accessibilityLabel="change country"
            accessibilityHint="click to change the country"
            accessibilityRole="combobox"
            testID="change-country"
            style={{
              height: '100%',
              minWidth: 32,
              justifyContent: 'center',
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: theme.spacing.s,
              marginLeft: theme.spacing.s,
              ...{
                ...flagButtonStyle,
                ...countryPickerButtonStyle,
              },
            }}
            disabled={disabled || disabledCountrySelect}
            onPress={(): void => setModalVisible(!modalVisible)}
          >
            {renderFlagButton({})}
            {!props.newStyle && code && layout === 'second' ? (
              <Text
                color={inputStyle?.color ?? 'black'}
                fontFamily={inputStyle?.fontFamily ?? 'primary'}
                fontSize={props.newStyle ? 's' : inputStyle?.fontSize ?? 'l'}
                fontStyle={inputStyle?.fontStyle ?? 'normal'}
                fontWeight={inputStyle?.fontWeight ?? '400'}
                mr="m"
                {...inputStyle}
              >{`+${code}`}</Text>
            ) : null}
            {!disableArrowIcon && <React.Fragment>{renderDropdownImage ? renderDropdownImage : renderDropdownImageControlled()}</React.Fragment>}
          </TouchableOpacity>
          <Box
            flex={1}
            justifyContent="center"
            height="100%"
            backgroundColor={props.disabled ? inputInactiveStyle?.style?.backgroundColor ?? 'gray100' : inputStyle?.backgroundColor ?? 'white'}
            borderRadius={inputStyle?.borderRadius ?? 'xs'}
            {...(props.newStyle
              ? {
                  flexDirection: 'column',
                }
              : {
                  flexDirection: 'row',
                  alignItems: 'center',
                })}
            {...(textContainerStyle as object)}
          >
            {(props.newStyle ? showLabel && props.label : props.label) ? (
              <Text
                accessibilityLabel={props.label}
                accessibilityHint="field"
                color="gray700"
                {...(props.newStyle
                  ? {
                      mb: 1,
                      fontWeight: '600',
                      fontSize: 's',
                    }
                  : {
                      my: 'xs',
                      fontSize: 'm',
                      fontWeight: '700',
                    })}
              >
                {props.label}
              </Text>
            ) : null}
            <Box
              backgroundColor={props.disabled ? inputInactiveStyle?.style?.backgroundColor ?? 'gray100' : inputStyle?.backgroundColor ?? 'white'}
              flexDirection="row"
              alignItems="center"
              borderRadius={inputStyle?.borderRadius ?? 'xs'}
              {...(props.newStyle
                ? {}
                : {
                    flex: 1,
                    height: '100%',
                  })}
              {...(textContainerStyle as object)}
            >
              {(props.newStyle ? showLabel : code && layout === 'first') ? (
                <Text
                  color={inputStyle?.color ?? 'black'}
                  fontFamily={inputStyle?.fontFamily ?? 'primary'}
                  fontSize={props.newStyle ? 'm' : inputStyle?.fontSize ?? 'l'}
                  fontStyle={inputStyle?.fontStyle ?? 'normal'}
                  fontWeight={inputStyle?.fontWeight ?? '400'}
                  {...inputStyle}
                >{`+${code}`}</Text>
              ) : null}
              <Input
                accessibilityLabel="phone number"
                accessibilityHint="phone number input"
                flex={1}
                color={inputStyle?.color ?? 'black'}
                fontFamily={inputStyle?.fontFamily ?? 'primary'}
                // @ts-ignore
                fontSize={inputStyle?.fontSize ?? 'l'}
                fontStyle={inputStyle?.fontStyle ?? 'normal'}
                fontWeight={inputStyle?.fontWeight ?? '400'}
                backgroundColor={props.disabled ? inputInactiveStyle?.style?.backgroundColor ?? 'gray100' : inputStyle?.backgroundColor ?? 'white'}
                textAlignVertical="center"
                height="100%"
                fullWidth
                borderWidth={0}
                {...(textInputStyle as object)}
                placeholder={phoneInputOptions?.text ?? 'Phone Number'}
                // @ts-ignore
                onChangeText={handleTextChange}
                value={getInputValue()}
                editable={!disabled}
                keyboardAppearance={withDarkTheme ? 'dark' : 'default'}
                keyboardType="number-pad"
                autoFocus={autoFocus}
                onKeyPress={props.onKeyPress}
                testID={testID}
                {...textInputProps}
                onFocus={handleSetShowLabel}
                onBlur={handleHideLabel}
                style={
                  props.newStyle
                    ? {
                        height: 24,
                        padding: 0,
                        marginLeft: 4,
                      }
                    : {}
                }
                px="m"
                py="none"
              />
              {props.newStyle && disabled ? (
                <Box mr={4}>
                  <LockIcon width={9} height={12} strokeWidth={0} />
                </Box>
              ) : null}
            </Box>
            {!props.newStyle && disabled ? (
              <Box mr={4}>
                <LockIcon width={9} height={12} strokeWidth={0} />
              </Box>
            ) : null}
          </Box>
        </Box>
        {props.newStyle ? messageComponent : null}
        <CountrySelect
          onSelect={onSelect}
          withFilter
          withFlag
          withCallingCode
          filterProps={filterProps}
          countryCode={countryCode}
          disableNativeModal={disabled}
          visible={modalVisible}
          theme={withDarkTheme ? { ...DARK_THEME, itemHeight: 40 } : { ...DEFAULT_THEME, itemHeight: 40 }}
          onClose={(): void => setModalVisible(false)}
          {...countryPickerProps}
        />
      </CountryModalProvider>
    );
  }),
);
