import type { ReadPointsDeltaByOrderDto } from '@goparrot/loyalty-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import type { AxiosError } from 'axios';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { QueryKeysEnum } from './keys';

export const useGetPointsEarnByOrder = (
  orderId: string,
  storeId?: string,
  options?: UseQueryOptions<ReadPointsDeltaByOrderDto | false, AxiosError, ReadPointsDeltaByOrderDto, any>,
): UseQueryResult<ReadPointsDeltaByOrderDto> => {
  const getEarnedPoints = async (): Promise<ReadPointsDeltaByOrderDto> => {
    return axios.get(`/api/v2/stores/${storeId}/loyalties/order-earning`, { params: { orderId } });
  };

  return useQuery(QueryKeysEnum.getLoyaltyPoints, () => !!storeId && getEarnedPoints(), options);
};
