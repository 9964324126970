import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import { IntlProvider } from 'react-intl';

import enMessages from './messages/en-US';

export type IntlMessageID = keyof typeof enMessages;
const messages: Record<string, typeof enMessages> = {
  'en-US': enMessages,
};
export const ReactIntlProvider: FC<PropsWithChildren<{ messages?: Record<string, string>; locale: string }>> = ({ children, ...props }) => {
  return (
    <IntlProvider {...props} messages={{ ...props.messages, ...messages[props.locale] }}>
      {children}
    </IntlProvider>
  );
};
export { createIntl, createIntlCache, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
