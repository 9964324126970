export const capitalizeFirstLetter = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);

export const pluralise = (value: number): string => (value > 1 ? 's' : '');

export const trimObjectTextFields: <T>(data: T) => T = (data) => {
  for (const item in data) {
    if (typeof data[item] === 'string') {
      // @ts-ignore we check for string, so trim exists.
      data[item] = data[item].trim();
    }
  }
  return data;
};

export const findCorrectAlign = (align?: 'center' | 'auto' | 'left' | 'right' | 'justify'): 'flex-start' | 'flex-end' | 'center' => {
  switch (align) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    default:
      return 'center';
  }
};
