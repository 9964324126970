import type { ReadStoreLoyaltyRewardDto } from '@goparrot/loyalty-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import type { AxiosError } from 'axios';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { QueryKeysEnum } from './keys';

export const useGetApplicableLoyalties = (
  options?: UseQueryOptions<ReadStoreLoyaltyRewardDto[], AxiosError, ReadStoreLoyaltyRewardDto[], any>,
): UseQueryResult<ReadStoreLoyaltyRewardDto[], AxiosError> => {
  const cart = useCartState();
  const { storeId } = useWebStore();

  const getLoyalties = async (): Promise<ReadStoreLoyaltyRewardDto[]> => {
    return axios.get(`/api/v2/stores/${storeId}/loyalty-rewards`);
  };

  return useQuery([QueryKeysEnum.applicableLoyalties, cart?.updated_at], getLoyalties, { ...options, retry: 0 });
};
